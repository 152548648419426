import * as React from "react"
import Layout from "../components/layout"
import Helmet from 'react-helmet'
import Articles from "../components/articles"
import useIntersection from '../components/useIntersection'; 
import classNames from 'classnames';

const Section = ({  id, className, children }) => {
  const [ref, isIntersecting] = useIntersection({
    root: null,
    rootMargin: '0px',
    threshold: 0.15
  });

  return (
    <section
      id={id}
      ref={ref}
      className={classNames(className, { 'not-in-view': !isIntersecting })}
    >
      {children}
    </section>
  );
};


const ArticleSecuirty = () => {
  return (
  <>
<Helmet
    bodyAttributes={{
        class: 'article-page whitemain'
    }}
/>
  <Layout pageTitle="ArticleSecuirty">
        <section id="main">
      <div id="article-intro">
        <div className="left-col">
          <h1><span className="revealblue">6 Security tips to protect your website</span></h1>
          <p><span className="revealblack">DEV INSIGHTS | 6 FEB 2016</span></p>
        </div>
        <div className="right-col picture">
            <img src={'/img/single/single-news2.jpg'} width="840" alt="Security tips" />
        </div>
      </div>
    </section>

    <Section id="article-content">
      <div id="inner-content">

        <h2>Don’t get hacked! Keeping your website secure is important in order to ensure your website is protected from malware and hackers.</h2>
<p>We will look at the 6 simplest ways to help improve your website security. Not only for web developers, but also for anyone who has, and maintains a website and users who use the internet.</p>

<h2>1. Strong Passwords</h2>
<p>To keep your account passwords safe, you need to use a unique password for each of your important accounts e.g. social media accounts, bank account, your email password and your WordPress (or other platform) password, also do not use ‘admin’ as the username for your website’s backend. Here’s tips how to create a strong password:</p>
<ul>
	<li>Use a mix of letters, numbers, symbols, capital letters and lowercase letters</li>
	<li>12 characters minimum</li>
	<li>Don’t substitute obvious number and letters</li>
	<li>Don’t use consecutive numbers like “123” or simple dictionary words, personal information or part of your domain name.</li>
	</ul>
<p>There are automated robots out there that will try to guess your passwords. Don’t make it easy for them. Try using a password manager in order to remember all strong passwords,, <a href="http://lifehacker.com/5529133/five-best-password-managers" target="_blank" rel="noreferrer">see 5 best password managers</a>.</p>

<h2>2.&nbsp;Security Review</h2>
<p>Do you want to keep your website safe and increase website performance? That’s a loaded question since every site owner wants their site to be safe and fast, but what are you doing to protect your website from hackers? Here’s our recommendations to start with:</p>
<ul>
	<li>Scanning your website for malware and vulnerabilities with <a href="https://sitecheck.sucuri.net" target="_blank" rel="noreferrer">Sucuri’s Site Check</a>, <a href="https://www.magereport.com" target="_blank" rel="noreferrer">Byte’s MageReport.com</a> for check Magento site security, and <a href="http://wprecon.com" target="_blank" rel="noreferrer">wprecon.com</a> for check WordPress site security.</li>
	<li>Ask your developer to check for vulnerabilities on any of his/her coding using the <a href="https://www.owasp.org/index.php/Category:Vulnerability_Scanning_Tools" target="_blank" rel="noreferrer">vulnerability scanning tools</a>.</li>
	<li>Check your WordPress or Magento’s admin users, make sure you don’t use ‘admin’ as the username but change to a strong password.</li>
	<li>Install and run ant-virus software. There is a popular free software from <a href="http://www.avira.com/en/free-antivirus-mac" target="_blank" rel="noreferrer">Avira’s Antivirus for both Mac and PC</a>.</li>
	<li>Keep your computer softwares updated to the latest versions and update WordPress and plugins &nbsp;(or other platforms).</li>
	<li>For WordPress site, consider adding <a href="https://wordpress.org/plugins/wordfence/" target="_blank" rel="noreferrer">Wordfence</a> or <a href="https://wordpress.org/plugins/better-wp-security/" target="_blank" rel="noreferrer">iTheme Security</a> and <a href="https://wordpress.org/plugins/wp-super-cache/" target="_blank" rel="noreferrer">WP Super Cache</a> plugins to improve site security and performance.</li>
	</ul>
<p>We also suggest a reminder in you calendar every 6 months to review your website security. &nbsp;Remember to ask your developer to help with improving site performance as every user wants their website to load faster.</p>
	
<h2>3.&nbsp;Update WordPress, Plugins or any of other platforms</h2>
<p>Running the latest versions of the software you use is important. This also includes any of the platforms or security patches on Magento which provide fixing known bugs or security.</p>
<p>We recommend to completely uninstall and delete any unused plugins / modules / addons / files from the server. Do not just deactivate them and do not leave the “old version” of your website on the server. Old sites get neglected and forgotten and it becomes an easy target for malware and hackers.</p>

<h2>4.&nbsp;Secure Hosting with Regular Backups</h2>
<p>Beware of cheaper hosting as they are not all equal. It depends on the level of support, regular maintenance and keep up to date with server software and regularly patched to ensure the highest security.</p>
<p>Here at Tobstar, we offer reasonable hosting solutions to clients with&nbsp;our reseller hosting, which we use to manage web hosting on behalf of our clients. With personality and fast support from the hosting company this can provide&nbsp;some cost saving for web development.</p>
<p>When choosing your own hosting, ensure the service includes regular backups (daily) and restore.&nbsp;We also offer this, included in our hosting, for our client’s&nbsp;peace of mind.</p>

<h2>5.&nbsp;Use SSL</h2>
<p>
  SSL (Secure Socket Layer) or HTTPS is commonly used on eCommerce or credit card payment pages for sending secure data across the web browser and server, securely with an encrypted link between those. But in reality, SSL would be a great security step for whenever a user is passing sensitive information to the web server such as username and password, as you can see this in the url bar at top of the browser window with mark by http<strong>s</strong>&#47;&#47; instead of http&#58;&#47;&#47; with a little security lock. To use SSL you need to purchase a SSL certificate from a web host and install it on the server.&nbsp;<a href="https://googlewebmastercentral.blogspot.com.au/2014/08/https-as-ranking-signal.html" target="_blank" rel="noreferrer">Google</a> is encouraged for all website owners to change from HTTP to HTTPS to keep everyone safe on the web and give the website a lightweight ranking signal.
</p>
<p>
  We recommend talking with your web developer to install SSL on your website and enable all pages on HTTPS. Also it helps users to trust your website as genuine and safe!
</p>

<h2>6. For&nbsp;Developers</h2>
<p>A basic understanding of those security tips for your website from your web developer is important. Their job is ensuring your website is safe and protected such as;</p>
<ul>
	<li>File and folder permission</li>
	<li>Server configuration file to protect from brute force attack</li>
	<li>Preventing of Sql injection and XSS</li>
	<li>Disabled server’s error message or server’s software information</li>
	<li>Using of validation forms and captcha</li><li>Secured and restriction on file upload</li>
	<li>Use of MD5 encrypt on password when saved or use for verify login</li>
	</ul>
<p>We encourage you to check with your web developer, all of the above, to ensure your website is safe and is using <a href="https://www.owasp.org/index.php/Category:Vulnerability_Scanning_Tools" target="_blank" rel="noreferrer">vulnerability scanning tools</a>.</p>
<p>Hopefully these tips will help keep your website safe. It’s a good idea to make a plan to put these into action with only a little time and money involved to protect your website.</p>
      </div>
    </Section>
<Articles></Articles>
</Layout>

  </>
  )
}

export default ArticleSecuirty


export const Head = () => (
  <>
    <title>6 Security tips to protect your website - Tobstar</title>
    <meta name="description" content="We will look at 6 simplest ways to help improve your website security. Not only for web developers, but for anyone who has maintains a website." />
  </>
)